import React, { useState } from "react";
import styled from "styled-components";
import themeCSS from "@styled-system/css";
import { useMediaQuery } from "../../lib/helpers/useMediaQuery";
import { SliderButton } from "../SliderButton";
import {
  CarouselProvider,
  Slider,
  Image,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const StyledSlider = styled.article`
  overflow: hidden;
  position: relative;

  .carousel__inner-slide {
    ${themeCSS({
      width: ["100%", "600px", "653px", "753px"],
      maxWidth: "95%",
      mx: "auto",
    })};
  }
`;
export const SlideWrapper = styled.article`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  ${themeCSS({
    position: "relative",
    overflow: "hidden",
    paddingTop: ["86.25%", "90.25%", "86.25%", "76.25%"],
    height: "0",
    width: ["100%", "100%", "100%", "100%"],
  })};

  & > img {
    ${themeCSS({
      position: "absolute",
      top: "0",
      left: "0",
      height: ["80%", "83%", "84%", "90%"],
      width: "100%",
      objectFit: "cover",
      objectPosition: "center center",
    })};
  }

  & > p {
    @media screen and (min-width: 445px) and (max-width: 510px) {
      font-size: 14px;
      line-height: 18px;
    }
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    z-index: 99;
  }
`;
export const ButtonSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;

  ${themeCSS({
    top: ["29%", "36%", "34%", "36%"],
    left: ["", "10%", "4%", "4%"],
    width: ["100%", "82%", "92%", "92%"],
  })};
`;

const StyledButton = styled.div`
  margin: auto;
  background-color: rgba(245, 245, 245, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
`;
const StyledButtonLeft = styled(StyledButton)`
  transform: rotate(180deg);
`;
const CarouselDots = styled(DotGroup)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;
const CarouselDotsButton = styled(Dot)`
  margin: 0 0.25rem;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.link : theme.colors.disabled};
  border-radius: 50%;
  width: 5px;
  height: 5px;
`;

const SliderComponent = () => {
  const isMobile = useMediaQuery(445);

  const CAROUSEL_IMAGES = [
    {
      image: "./images/Kvarnliden_Exterior2_201117-min.jpg",
      text: "",
    },
    {
      image:
        "./images/MM_Kvarnliden_Exterior_Street_view_ZynkaVisual_191111-min.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ1_1-rok.jpg",
      text: "1 ROK 30,63m2 ca:5000:- ",
    },
    {
      image: "./images/lagenhet-typ2_1-rok.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ3_1-rok.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ4_1-rok.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ5_1,5-rok.jpg",
      text: "1,5 ROK 34,62m2 ca:5500:-",
    },
    {
      image: "./images/lagenhet-typ6_1,5-rok.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ7_2-rok.jpg",
      text: "2 ROK 43,77m2 ca 6700:-",
    },
    {
      image: "./images/lagenhet-typ8_2-rok.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ9_2-rok+loft.jpg",
      text: "",
    },
    {
      image: "./images/lagenhet-typ10_2,5-rok.jpg",
      text: "2,5 ROK 54,94 ca 7500:-",
    },
    {
      image: "./images/lagenhet-typ11_4-rok.jpg",
      text: "S4 ROK 73,12m2 ca:9300:-",
    },
  ];
  return (
    <StyledSlider>
      <CarouselProvider
        naturalSlideWidth={100}
        // naturalSlideHeight={190}
        isIntrinsicHeight
        totalSlides={CAROUSEL_IMAGES.length}
        step={1}
        visibleSlides={isMobile ? 1 : 1.4}
        dragEnabled
      >
        <Slider>
          {CAROUSEL_IMAGES.map(({ image, text, index }) => (
            <Slide index={index}>
              <SlideWrapper>
                <Image src={image} alt="" width="633" height="400" />
                <p>{text}</p>
              </SlideWrapper>
            </Slide>
          ))}
        </Slider>
        <ButtonSection>
          <ButtonBack>
            <StyledButtonLeft>
              <SliderButton />
            </StyledButtonLeft>
          </ButtonBack>

          <ButtonNext>
            <StyledButton>
              <SliderButton />
            </StyledButton>
          </ButtonNext>
        </ButtonSection>
        <CarouselDots
          renderDots={({ currentSlide }) =>
            CAROUSEL_IMAGES.map((image, index) => (
              <CarouselDotsButton
                slide={index}
                isActive={currentSlide === index}
                key={`dot-${index}`}
              />
            ))
          }
        />
      </CarouselProvider>
    </StyledSlider>
  );
};

export default SliderComponent;
