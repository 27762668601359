/* eslint-disable prefer-destructuring */
const scale = ["445px", "1024px", "1440px", "1920px"];

// aliases
scale.sm = scale[0];
scale.md = scale[1];
scale.lg = scale[2];
scale.xl = scale[3];

scale.mobile = scale[0];
scale.tablet = scale[1];
scale.desktop = scale[2];
scale.widedesktop = scale[3];

export const breakpoints = scale;
