import React from "react";

import MaxWidth from "../MaxWidth";
import { Button } from "../Button";

import {
  Container,
  StyledTextWithImage,
  TextWrapper,
  Header,
  TextStyled,
} from "./style";

export const TextWithTwoColumns = ({
  data,
  sourceUrl,
  header,
  preamble,
  link,
  label
}) => {
  return (
    <Container>
      <MaxWidth>
        <StyledTextWithImage align={data && data.align === "right"}>
          <Header>{header}</Header>
          <TextWrapper column={data && data.align}>
            <TextStyled dangerouslySetInnerHTML={{ __html: preamble }} />
            {/* <TextStyled>{preamble}</TextStyled> */}

            <Button label={label} typestyle="secondary" link={link} />
          </TextWrapper>
        </StyledTextWithImage>
      </MaxWidth>
    </Container>
  );
};
export default TextWithTwoColumns;
