import styled from "styled-components";
import themeCSS from "@styled-system/css";

export const Container = styled.article`
  background-image: url("./images/yellow-pattern-v3.png");
  background-size: cover;
  margin-top: 0;
  ${themeCSS({
    py: ["3.5rem", "3.5rem", "xxlarge"],
  })}
`;
export const StyledTextWithImage = styled.div`
  grid-template-areas: "left right";
  ${themeCSS({
    display: ["flex", "flex", "grid", "grid"],
    gridTemplateColumns: "1fr 1fr",
    gridAutoFlow: "dense",
    flexDirection: "column",
    minHeight: ["auto", "auto", "", ""],
  })};
`;

export const Header = styled.h2`
  font-family: "The Snowday";
  font-size: 6rem;
  line-height: 76.5px;
  ${themeCSS({
    mb: "2rem",
    color: "secondary",
    maxWidth: ["100%", "100%", "calc(100% - 15%)", "calc(100% - 15%)"],
  })}
`;

export const TextWrapper = styled.div`
  ${themeCSS({
    maxWidth: ["100%", "100%", "calc(100% - 15%)", "calc(100% - 15%)"],
  })}
`;
export const TextStyled = styled.p`
  ${themeCSS({
    color: "secondary",
    fontSize: "1.125rem",
    fontFamily: "body",
    lineHeight: "27px",
    marginBottom: "40px",
  })};
  & > strong {
    font-weight: 700;
    line-height: 44px;
    & > a {
      text-decoration: underline;
      ${themeCSS({
        color: "secondary",
      })};
    }
  }

  & > a {
    text-decoration: underline;
    ${themeCSS({
      color: "secondary",
    })};
  }
`;
