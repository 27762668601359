import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* CSS RESET */
  /* stylelint-disable */
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;vertical-align:baseline;margin:0;padding:0}
  article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display:block;}
  body {line-height:1}
  ol,ul{list-style:none}
  blockquote,q{quotes:none} 
  table {border-collapse:collapse;border-spacing:0}
  body,input,textarea,button{text-size-adjust: 100%;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing:grayscale;}
  input,textarea,button{appearance:none;border-radius:0;color:inherit;}
  input,textarea,button,select {font-size:inherit;color:inherit;}
  *, *:before, *:after { box-sizing: border-box; }
  details, summary {outline: none;}
  details summary::-webkit-details-marker {display: none;}
  button,input {background:none;border:0;padding:0;margin:0;line-height:1;color:inherit;font-size:inherit;}
  button,input[type="submit"] {cursor:pointer;}
  h1,h2,h3,h4,h5 {font-weight:normal;}
  a {text-decoration: none;}
  /* stylelint-enable */

  @font-face {
  font-family: 'The Snowday';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/the-snowday.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/the-snowday.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  }


  html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }

  html {
    &.mobile-menu-open {

      body {
        position: fixed;
        overflow: hidden;
      }
    }
  }

  body {
      font-size: "root";
      background-color: "white";
      margin-top:  "40px"
   
  }

  body.modal-open {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

`;
export default GlobalStyle;
