import React, { Component } from "react";
import Hero from "../Hero";
import TextWithTwoColumns from "../TextWithTwoColumns";
import MapWithText from "../MapWithText";
import Logo from "../Logo";
import TextSlider from "../TextSlider";
import Cta from "../Cta";
import Footer from "../Footer";
import GlobalStyle from "../../components/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "../../lib/theme";
import styled from "styled-components";
class App extends Component {
  render() {
    const StyledApp = styled.div`
      height: 100%;
      width: 100%;
    `;

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledApp>
          <Logo />
          <Hero />
          <TextWithTwoColumns
            header={"Starta ditt nya liv, på riktigt."}
            preamble="       Några minuter från Varbergs centrum (på Södra vägen om vi ska vara
              exakta) så ligger 85 nya lägenheter, varav 65 är speciellt
              framtagna för dig som är mellan 18 - 24 år. Det är smart planerade
              1,5- till 4-rumslägenheter. Här finns också cykelgarage. Och det
              kändes vettigt att bygga ett cykelkök för när du behöver fixa med
              cykeln. Huset är helt digitaliserat. Förutom WiFi är huset fritt
              från nycklar – du låser istället upp med en tagg. I kvarteret
              öppnar en helt ny kvarterskrog. Fyll i en anmälan så kan ett
              förstahandskontrakt på en alldeles egen lägenhet faktiskt bli din,
              med inflyttning i oktober i år."
            link="https://kvarnliden.nu/intresseanmalan/"
            label="Intresseanmälan"
          />
          <TextSlider />
          <MapWithText />
          <TextWithTwoColumns
            header="Vanliga frågor"
            preamble="<strong>Får alla som söker verkligen en lägenhet?</strong><br/>
            Vi har 85 nyproducerade hyresrätter och 65 av dem vänder sig först och främst till dig mellan 18-24 år. Först till kvarn gäller. Du kan läsa mer om vår <a href=https://manssonfastigheter.se/uthyrningspolicy/ TARGET=_blank>uthyrningspolicy här</a>.<br/>
            <strong>När kan jag flytta in?</strong><br/>
            De första 36 lägenheterna är redo för inflyttning 13 december 2021. Från 28 mars är resterande lägenheter helt klara.<br/>
            <strong>Måste jag flytta när jag fyller 25?</strong><br/>
            Självklart kan du bo kvar även efter att du fyllt 25. Men eftersom behovet av prisvärda lägenheter till unga människor är lika stort som utbudet är litet vänder vi oss först och främst till unga människor.<br/>
            <strong>Jag är äldre än 24. Kan jag ändå söka?</strong><br/>
            Vi vänder oss främst till sökande mellan 18-24 men för några av lägenheter är vi ändå öppna för äldre sökande som söker ett nytt och modernt boende.<br/>
            <strong>Fler frågor?</strong> "
            label="Kontakta oss här"
            link="mailto:info@kvarnliden.nu"
          />

          <Cta />
          <Footer />
        </StyledApp>
      </ThemeProvider>
    );
  }
}

export default App;
