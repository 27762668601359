import React from "react";
import MaxWidth from "../MaxWidth";
import styled from "styled-components";

export const Wrapper = styled.a``;

export const StyledLogoType = styled.img`
  position: absolute;
  width: 124px;
  height: 52px;
  z-index: 111;
  margin: ${({ theme }) => theme.space.small};
`;

const Logo = () => {
  return (
    <Wrapper>
      {/* <MaxWidth> */}
      <StyledLogoType
        src="/images/mansson-fastigheter-logo.svg"
        alt="mansson-fastigheter-logo"
      />
      {/* </MaxWidth> */}
    </Wrapper>
  );
};

export default Logo;
