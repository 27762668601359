import styled from "styled-components";
import PropTypes from "prop-types";

import { space, layout, variant, compose, flexbox } from "styled-system";

const styles = compose(space, layout);

const variants = {
  prop: "maxSize",
  variants: {
    max: {
      width: "100%",
      maxWidth: ["maxWidths.mobile", "maxWidths.max"],
      px: ["small", "small", "xlarge"],
      mx: "auto",
    },
    fullWidth: {
      width: "100%",
      maxWidth: "maxWidths.mobile",
      px: "0rem",
      mx: "0rem",
    },
    fullWidthMobile: {
      width: "100%",
      maxWidth: ["maxWidths.mobile", "maxWidths.mobile", "maxWidths.max"],
      px: ["0rem"],
      mx: ["0rem", "0rem", "auto"],
    },
  },
};

export const MaxWidthStyled = styled.section`
  ${variant(variants)}
  ${styles}
  ${space}
  ${flexbox}
  ${layout}
`;

MaxWidthStyled.propTypes = {
  maxSize: PropTypes.oneOf(["max", "fullWidth", "fullWidthMobile"]),
};

MaxWidthStyled.defaultProps = {
  maxSize: "max",
};
