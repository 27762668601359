const namedColors = {
  default: "#FFED00",
  white: "#ffffff",
  black: "#0A0401",
  primary: "#FFED00",
  secondary: "#DF4649",
  accent: "#2FA374",
  accentSecondary: "#028DC6",
  preamble: "#414141",
  textInactive: "#808c9d",
  link: "#4F4F4F",
  linkHover: "#828282",
  disabled: "#BDBDBD",
  error: "#DF4649",
  errorSecondary: "#EC8B5A",
  warning: "#F3DB05",
  success: "#2FA374",
  primaryHover: "#F3DB05",
  secondaryHover: "#D72D30",
};

const functionColors = {
  primary: namedColors.default,
  secondary: namedColors.secondary,
  accent: namedColors.accent,
  textPrimary: namedColors.black,
  textSecondary: namedColors.gray,
  preamble: namedColors.preamble,
  invalid: namedColors.error,
  success: namedColors.success,
};

export const colors = {
  ...namedColors,
  ...functionColors,
};
