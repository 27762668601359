/* eslint-disable prefer-destructuring */
const fontSizes = [18, 13, 14, 16, 18, 20, 25, 27, 28, 32, 40, 48, 64];

fontSizes.root = fontSizes[0];

fontSizes.desktop = {
  preamble: 28,
  button: 18,
  buttonLink: 20,
  menu: 8,
  bodyXS: 14,
  bodyS: 16,
  body: 20,
  h5: 20,
  h4: 25,
  h3: 32,
  h2: 48,
  h1: 64
};

fontSizes.mobile = {
  preamble: 20,
  button: 16,
  buttonLink: 20,
  menu: 18,
  bodyXS: 13,
  bodyS: 16,
  body: 18,
  h5: 20,
  h4: 20,
  h3: 27,
  h2: 28,
  h1: 40
};

export const fonts = {
  body: "'Roboto', sans-serif",
  heading: "'Roboto', sans-serif",
  button: "'Roboto', sans-serif"
};

export const typography = {
  fontSizes,
  fonts
};
