import React from "react";
import styled from "styled-components";
import themeCSS from "@styled-system/css";
import MaxWidth from "../MaxWidth";

import SliderComponent from "../SliderComponent";
const StyledTextSlider = styled.article`
  /* height: 677px; */
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;

  margin-right: 0;
  ${themeCSS({
    display: ["flex", "flex", "grid", "grid"],
    justifyContent: ["unset", "unset", "center", "center"],
    alignItems: ["unset", "unset", "center", "center"],
    flexDirection: ["column", "column", "row", "row"],
    py: ["4rem", "4rem", "medium", "xxlarge"],
  })}
`;
export const TextWrapper = styled.section`
  display: flex;
  flex-direction: column;

  ${themeCSS({
    width: ["100%", "100%", "90%", "90%"],
  })}
`;
export const Header = styled.h2`
  font-family: "The Snowday";
  font-size: 5.5rem;
  line-height: 72px;
  margin-bottom: 2rem;
  ${themeCSS({
    color: "secondary",
    maxWidth: ["100%", "100%", "100%", "100%"],
  })};
`;

export const TextStyled = styled.p`
  ${themeCSS({
    color: "prereamble",
    fontSize: "1.125rem",
    fontFamily: "body",
    lineHeight: "29px",
    marginBottom: "40px",
  })};
`;
const TextSlider = () => {
  return (
    <MaxWidth>
      <StyledTextSlider>
        <TextWrapper>
          <Header>Kv. Kvarnliden</Header>
          <TextStyled>
            Lägenheterna är ljusa, materialen fräscha och arkitekturen smart.
            Ja, smart både när det kommer till utseende och digitala funktioner.
            Service och hållbarhet har varit tanken från start – här kan du byta
            tjänster och grejer med varandra. Eller äta en bit mat i kvarterets
            helt nya restaurang som öppnar inom kort.
          </TextStyled>
        </TextWrapper>
        <SliderComponent />
      </StyledTextSlider>
    </MaxWidth>
  );
};

export default TextSlider;
