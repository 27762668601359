import React from "react";
import { Arrow } from "../Arrow";
import styled from "styled-components";

export const DefaultButton = styled.a`
  display: flex;
  height: 50px;
  min-width: 154px;
  justify-content: center;
  width: ${({ btnWidth }) => (btnWidth ? btnWidth : "fit-content")};
  padding: 11px 23px;
  align-items: center;
  background-color: transparent;

  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.secondary};
  fill: ${({ theme }) => theme.colors.secondary};
  transition: 0.25s ease-in-out;
  cursor: pointer;
  white-space: nowrap;

  :focus,
  :active,
  :hover {
    outline: none;
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
  & > svg {
    margin-left: 0.5rem;
  }
`;

export const SecondaryButton = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  fill: ${({ theme }) => theme.colors.primary};

  :focus,
  :active,
  :hover {
    background-color: ${({ theme }) => theme.colors.secondaryHover};

    outline: none;
  }
`;

// const Wrapper = ({ link, children }) => {
//   if (link) {
//     const url = link.replace(EXTERNAL_DOMAIN, "");
//     return (
//       <Link href={url} passHref>
//         {children}
//       </Link>
//     );
//   }
//   return <>{children}</>;
// };

export const Button = ({
  typestyle,
  label,
  noteLabel,
  link,
  btnWidth,
  active = false,
  target,
  className,
  disabled,
  ...rest
}) => {
  switch (typestyle) {
    case "secondary":
      return (
        <a href={link} className={className}>
          <SecondaryButton
            {...rest}
            active={active}
            btnWidth={btnWidth}
            disabled={disabled}
            target={target}
          >
            {label}
            <Arrow />
          </SecondaryButton>
        </a>
      );

    default:
      return (
        <a href={link} className={className}>
          <DefaultButton
            {...rest}
            active={active}
            btnWidth={btnWidth}
            target={target}
          >
            {label}
            <Arrow />
          </DefaultButton>
        </a>
      );
  }
};
