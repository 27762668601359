import React, { useState, useEffect } from "react";
import MaxWidth from "../MaxWidth";

import styled from "styled-components";
import themeCSS from "@styled-system/css";
import MapComponent from "../MapComponent";

export const StyledBackgroundImage = styled.article`
  overflow: hidden;
  background-image: url("./images/red-pattern-v3.png");
  background-size: cover;
  margin-top: 0;
  ${themeCSS({
    py: ["3.5rem", "3.5rem", "xxlarge"],
  })}
`;

const StyledMapWithText = styled.article`
  margin-top: 0;
  ${themeCSS({
    display: ["flex", "flex", "grid", "grid"],
    gridTemplateColumns: "1fr 1fr",
    gridAutoFlow: "dense",
    flexDirection: "column-reverse",
    alignItems: "center",
    height: ["auto", "auto", "558px", "558px"],
  })}
`;

const TextContainer = styled.section`
  ${themeCSS({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: ["100%", "100%", "70%", "438px"],
    mx: "auto",
    pl: "20px",
  })}

  & >h2 {
    font-family: "The Snowday";
    font-size: 6.25rem;
    ${themeCSS({
      mb: "2rem",
      color: "primary",
      maxWidth: ["100%", "100%", "calc(100% - 15%)", "calc(100% - 15%)"],
    })}
  }

  & > p {
    ${themeCSS({
      color: "white",
      fontSize: "1.125rem",
      fontFamily: "body",
      lineHeight: "29px",
      marginBottom: "40px",
    })};
  }
`;
export const ImageWrapper = styled.div`
  ${themeCSS({
    position: "relative",
    overflow: "hidden",
    paddingTop: "86.25%",
    height: "0",
    width: ["100%", "100%", "86.25%", "86.25%"],
  })};

  & > img {
    ${themeCSS({
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      objectFit: "cover",
      objectPosition: "center center",
    })};
  }
`;
const MapWithText = () => {
  return (
    <StyledBackgroundImage>
      <MaxWidth>
        <StyledMapWithText>
          <ImageWrapper>
            <MapComponent />
          </ImageWrapper>
          <TextContainer>
            <h2>Hitta hit</h2>
            <p>
              10 minuter från centrum hittar du en ungdomligt kvarter i en
              modern stadsdel. Smarta lägenheter, nära till bra service – allt
              från cykelkök till resturangkök.
            </p>
          </TextContainer>
        </StyledMapWithText>
      </MaxWidth>
    </StyledBackgroundImage>
  );
};

export default MapWithText;
