import React from "react";
import styled from "styled-components";

const StyledSliderButton = styled.svg`
  path {
    /* ${(props) =>
      props.isFilled ? "fill: #var(--dark-grey)" : "fill: none"}; */
  }
`;
export const SliderButton = () => {
  return (
    <StyledSliderButton
      // {...props}
      width="13"
      height="19"
      viewBox="0 0 13 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1001 9.5L2.6001 0L0.700096 1.9L8.3001 9.5L0.700096 17.1L2.6001 19L12.1001 9.5Z"
        fill="black"
      />
    </StyledSliderButton>
  );
};

export default SliderButton;
