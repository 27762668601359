import { typography } from "./typography";
import { breakpoints } from "./breakpoints";
import { mediaQueries } from "./media-queries";
import { colors } from "./colors";
import { space } from "./space";
import { sizes } from "./sizes";

export const theme = {
  mq: mediaQueries,
  breakpoints,
  colors,
  sizes,
  space,
  ...typography,
};
export default theme;
