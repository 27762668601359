import React, { useState, useEffect } from "react";
import styled from "styled-components";
import themeCSS from "@styled-system/css";

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${themeCSS({
    backgroundColor: "white",
    py: ["2.25rem", "3.25rem", "3.25rem", "medium"],
  })}

  & > p {
    ${themeCSS({
      textAlign: "center",
      color: "black",
      fontSize: ["0.75rem", "1.125rem"],
      fontFamily: "body",
      lineHeight: ["140%", "29px"],
    })};
    & > a {
      text-decoration: underline;
      color: black;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <p>Månsson Fastigheter, Polhemsplatsen 5, 411 11 Göteborg</p>
      <p>
        {" "}
        Copyright © 2021 Månsson Fastigheter, All rights reserved. {""}
        <a
          href="https://manssonfastigheter.se/wordpress/wp-content/uploads/2020/08/mansson-fastigheter-integritetspolicy.pdf"
          TARGET="_blank"
        >
          Integritetspolicy
        </a>
      </p>
    </StyledFooter>
  );
};

export default Footer;
