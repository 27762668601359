import React from "react";
import styled from "styled-components";

const StyledArrow = styled.svg`
  path {
    /* ${(props) =>
      props.isFilled ? "fill: #var(--dark-grey)" : "fill: none"}; */
  }
`;
export const Arrow = () => {
  return (
    <StyledArrow
      // {...props}
      width="20"
      height="20"
      viewBox="0 0 36 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1007 40.4073L35.4211 21.9073V19.0927L17.1007 0.592705L14.2585 3.4073L29.2047 18.5H0V22.5H29.2047L14.2585 37.5927L17.1007 40.4073Z"
      />
    </StyledArrow>
  );
};

export default Arrow;
