import React from "react";
import { Button } from "../Button";
import styled from "styled-components";
import themeCSS from "@styled-system/css";
import { useMediaQuery } from "../../lib/helpers/useMediaQuery";

const StyledCta = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/images/potrait-cta.jpg");
  max-width: 100%;
  height: 599px;
  ${themeCSS({
    backgroundSize: ["150%", "150%", "cover"],
    backgroundPosition: ["50% 90%", "50% 65%"],
    pt: ["4rem", "4rem", "xxlarge"],
    pb: ["4rem", "small", "xxlarge"],
  })}
`;
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${themeCSS({
    color: "white",
    width: ["85%", "100%", "100%", "100%"],
    pt: ["4rem", "4rem", "large"],
    pb: ["4rem", "small", "large"],
  })}
  &> h2 {
    text-align: center;
    font-family: "The Snowday";
    font-size: 6.25rem;
    ${themeCSS({
      color: "white",
      mb: ["medium"],
    })}
  }
`;

export const Cta = () => {
  const isMobile = useMediaQuery(445);
  return (
    <StyledCta>
      <Wrapper>
        <h2>Vill du veta mer?</h2>
        <Button label="Kontakta oss" link="mailto:info@kvarnliden.nu" />
      </Wrapper>
    </StyledCta>
  );
};

export default Cta;
