const scale = Array.from(Array(101).keys()).map((number) => {
  return number * 4;
});

scale.xxsmall = "0.445rem"; // 8x
scale.xsmall = "0.89rem"; // 16px
scale.small = "1.33rem"; // 24px
scale.medium = "3rem"; // 48px
scale.large = "3.56rem"; // 64px
scale.xlarge = "4.89rem"; // 88px
scale.xxlarge = "6.89rem"; // 124px
scale.cardMobile = "1.625rem"; // 24px
scale.cardDesktop = "2.0625rem"; // 33px

export const space = scale;
