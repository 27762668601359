import React from "react";
import { Button } from "../Button";
import themeCSS from "@styled-system/css";

import styled from "styled-components";
import { useMediaQuery } from "../../lib/helpers/useMediaQuery";

const StyledHero = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${themeCSS({
    overflow: "hidden",
    height: ["100vh", "80vh", "0", "0"],
    width: "100%",
    paddingTop: ["50.25%", "48.25%"],
  })}

  & > img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;
  }
`;
const Header = styled.section`
  position: absolute;
  top: 0;
  ${themeCSS({
    overflow: "hidden",
    height: ["100%", "100%", "0", "0"],
    width: "100%",
    paddingTop: " 50.25%",
  })}
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    ${themeCSS({
      objectFit: ["contain", "contain"],

      transform: [
        "translate(0,35%)",
        "translate(0, 35%)",
        "translate(55%, 35%)",
        "translate(55%, 35%)",
      ],
      height: ["48%", "50%", "47%", "47%"],
      width: ["100%", "100%", "47%", "47%"],
    })}
  }
`;
const TextWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  color: white;
  ${themeCSS({
    top: ["67%", "70%", "60%", "65%"],
    width: ["100%", "100%", "70%", "48%"],
  })}
`;
const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  ${themeCSS({
    fontSize: ["1.25rem", "1.25rem", "1.5rem", "1.5rem"],
    px: "small",
    lineHeight: "140%",
  })}
`;

const Hero = () => {
  const isMobile = useMediaQuery(1024);
  return (
    <StyledHero>
      {isMobile ? (
        <img src="/images/hero-mobile.png" alt="hero" />
      ) : (
        <img src="/images/hero-v3.jpg" alt="hero" />
      )}
      <Header>
        {" "}
        {isMobile ? (
          <img src="/images/text-hero-mobile.png" alt="hero" />
        ) : (
          <img src="/images/text-hero.png" alt="hero" />
        )}
      </Header>
      <TextWrapper>
        <Text>
        Ett förstahandskontrakt på en nybyggd lägenhet<br /> i centrala Varberg kan bli ditt, utan att behöva stå i kö.
        </Text>
        <Button
          label="Intresseanmälan"
          typestyle="primary"
          link={"https://kvarnliden.nu/intresseanmalan/"}
        />
      </TextWrapper>
    </StyledHero>
  );
};

export default Hero;
